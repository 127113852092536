import { createMemoryHistory, createRouter } from 'vue-router'

// import IndexPage from '../pages/IndexPage.vue';
// import PadrePioES from '../pages/PadrePioES.vue';
// import Error404Page from '../pages/Error404Page.vue';


const routes = [
  { path: '/', name: 'Index', component: () => import("../pages/PadrePioES.vue"),
    children: [
      { path: '/1431As009aJs/luz-y-protecion-padre-pio-es', name: 'teste', component: () => import("../pages/PadrePioES.vue") },
    ]
  },
  { path: '/1431As009aJs/luz-y-protecion-padre-pio-es', name: 'teste', component: () => import("../pages/PadrePioES.vue") },
]

const router = createRouter({
  history: createMemoryHistory(),
  routes,
})

export default router;